.company {    
  width: 100%;
  display: flex;
  justify-content: center;
  float: left;
}
.moderator-company-logo {
  padding-top: 7px;
  height: 100%;
  float:left;
}

.moderatorOptions {
  display: flex;
  width: 40px;
  flex-direction: column;
  height: 100%;
  align-items: center;
}

.codeShareOptionForModerator {
  display: flex;
  width: 40px;
  flex-direction: column;
  height: 100%;
  align-items: center;
  padding-left: 5px;
}

.notesOptionForModerator{
  display: flex;
  width: 40px;
  flex-direction: column;
  height: 100%;
  align-items: center;
  padding-left: 5px;
}


.whiteboardOptionForModerator{
  display: flex;
  width: 40px;
  flex-direction: column;
  height: 100%;
  align-items: center;
  padding-left: 5px;
}

.moderatorOptionsDialogHeader {
  background-color:var(--primaryColor) !important; 
  border: none !important;
  color: white !important;
  /* padding: 10px 20px !important; */
  text-align: center !important;
  text-decoration: none !important;
  display: inline-block !important;
  /* font-size: 16px !important; */
  margin: 1px 2px !important;
  transition-duration: 0.4s !important;
}

.moderatorOptionsButton {
  background-color:var(--primaryColor) !important; 
  border: none !important;
  color: white !important;
  /* padding: 10px 20px !important; */
  text-align: center !important;
  text-decoration: none !important;
  display: inline-block !important;
  /* font-size: 16px !important; */
  margin: 4px 2px !important;
  transition-duration: 0.4s !important;
  cursor: pointer !important;
}

.buttonModeratorOptionsDialog {
  background-color: white !important; 
  color: black !important; 
  border: 2px solid var(--primaryColor) !important;
  width: 18%;
}

.buttonModeratorOptionsDialog:hover {
  background-color: var(--primaryColor) !important;
  color: white !important;
}

.getMeetingSubmissionsButton {
  background-color: var(--primaryColor) !important;
  color: white !important;
}

.getMeetingSubmissionsButton:hover {
  color: var(--primaryColor) !important;
  background-color: white !important;
  border: 1px solid var(--primaryColor) !important;
}

.adminViewMeetingDetailsTopNavBarContainer {
  height: 10vh;
}

.adminViewMeetingDetailsMeetingDataContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.adminViewMeetingDetailsTitle {
  height: 5vh;
  font-size: x-large;
  font-family: sans-serif;
  color: var(--primaryColor);
}

.adminViewMeetingDetailsTimeContainer {
  height: 5vh;
  display: flex;
  font-size: larger;
  font-family: sans-serif;
  color: var(--primaryColor);
}

.adminViewMeetingDetailsHasMeetingStarted {
  height: 5vh;
  font-size: larger;
  font-family: sans-serif;
  color: var(--primaryColor);
}

.adminViewMeetingDetailsHasMeetingEnded {
  height: 5vh;
  font-size: larger;
  font-family: sans-serif;
  color: var(--primaryColor);
}

.adminViewMeetingDetailsPublicLink {
  display: flex;
  width: 100%;
  color: var(--primaryColor);
  overflow-y: scroll;
  text-overflow: ellipsis;
  justify-content: center;
}

.adminViewMeetingDetailsModeratorsContainer {
  margin-top: 3vh;
  display: flex;
  flex-direction: column;
  width: 80%;

}

.adminViewMeetingDetailsModerators {
  display: flex;
  width: 100%;
  min-height: 15vh;
  /* overflow: scroll; */
  flex-direction: column;
  border-radius: 5px;
  border: solid 1px var(--primaryColor);
}

.adminViewMeetingDetailsModeratorDetailsContainer {
  display: flex;
  justify-content: center;
}


.adminViewMeetingDetailsAllModerators {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 80%;
}

.adminViewMeetingDetailsModeratorEmail {
  display: flex;
  justify-content: center;
  color: var(--primaryColor);
  font-family: sans-serif;
  font-size: large;
}

.adminViewMeetingDetailsModeratorLink {
  color: var(--primaryColor);
  overflow: scroll;
}

.adminViewMeetingDetailsParticipantsContainer {
  margin-top: 3vh;
  margin-bottom: 5vh;
  display: flex;
  flex-direction: column;
  width: 80%;
}

.adminViewMeetingDetailsParticipantDetailsContainer {
  display: flex;
  justify-content: center;
}

.adminViewMeetingDetailsAllParticipants {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.adminViewMeetingDetailsParticipants {
  display: flex;
  width: 80%;
  min-height: 15vh;
  /* overflow: scroll; */
  flex-direction: column;
  border-radius: 5px;
  border: solid 1px var(--primaryColor);
}

.adminViewMeetingDetailsParticipantEmail {
  display: flex;
  justify-content: center;
  color: var(--primaryColor);
  font-family: sans-serif;
  font-size: large;
}

.adminViewMeetingDetailsParticipantLink {
  color: var(--primaryColor);
  overflow: scroll;
}

.adminViewMeetingPassword {
  display: flex;
  font-family: sans-serif;
  font-size: large;
  color: var(--primaryColor);
}

.contentDetailsContainer {
  display: flex;
  width: 80%;
  align-items: center;
  margin-top: 3vh;
  margin-bottom: 5vh;
  flex-direction: column;
  height: 50vh;
  /* overflow-y: scroll; */
}

.contentDetailsTitleContainer {
  display: flex;
  font-size: medium;
  width: 100%;
}

.contentDetailsParticipantContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  border: 1px solid var(--primaryColor);
  padding: 3px;
}

.contentDetailsSubmissionRecordContainer {
  margin-top: 1vh;
  margin-bottom: 1vh;
  border: 1px solid var(--primaryColor);
  padding: 5px;
  font-family: sans-serif;
  font-size: large;
  color: var(--primaryColor);
}

.contentDetailsParticipantSubmissions {
  width: 90%;
}

.contentDetailsMetaDataContainer {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed black;
}

.contentDetailsLanguageIndicator {
  display: flex;
  width: 50%;
  justify-content: flex-start;
}

.contentDetailsSumittedAt {
  display: flex;
  width: 50%;
  justify-content: flex-end;
}

.participantsBoardDialogHeader {
  background-color: var(--primaryColor);
  color: white;
  display: flex;
  justify-content: center;
}

.closeParticipantsBoardDialog {
  border: 10px;
  box-sizing: border-box;
  display: inline-block;
  font-family: Roboto, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  text-decoration: none;
  margin: 0px;
  padding: 0px;
  outline: none;
  font-size: inherit;
  font-weight: inherit;
  position: relative;
  z-index: 1;
  height: 36px;
  line-height: 36px;
  width: 100px;
  border-radius: 2px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  background-color: rgb(245, 245, 245);
  text-align: center;
  box-shadow: rgb(0 0 0 / 12%) 0px 1px 6px, rgb(0 0 0 / 12%) 0px 1px 4px;
  color: var(--primaryColor);
  font-size: large;
}

.closeParticipantsBoardDialog:hover {
  background-color: rgba(50, 55, 101, 0.08)
}

.fetchParticipantsBoardDialogButton {
  border: 10px;
  box-sizing: border-box;
  display: inline-block;
  font-family: Roboto, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  text-decoration: none;
  margin: 0px;
  padding: 0px;
  outline: none;
  font-size: inherit;
  font-weight: inherit;
  position: relative;
  z-index: 1;
  height: 36px;
  line-height: 36px;
  width: 100%;
  border-radius: 2px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  background-color: var(--primaryColor);
  text-align: center;
  color: white;
  box-shadow: rgb(0 0 0 / 12%) 0px 1px 6px, rgb(0 0 0 / 12%) 0px 1px 4px;
  font-size: large;
  width: 100px;
}

.getMeetingSubmissionButtonsContainer {
  display: flex;
}

.participantDetails {
  height: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 1px 0.5px var(--primaryColor);
  width: 100%;
  padding: 0px 10px 0px 10px;
  border-radius: 5px;
  margin: 10px 0px;
  transition: 0.1s;
}

.participantDetails:hover {
  box-shadow: 0px 0px 4px 1px var(--primaryColor);
  transform: scale(1.05);
}

.participantsDetailsTable {
  width: 100%;
  margin-top: 10px;
}

.participantName {
  width: 40%;
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 15px;
  align-items: center;
  cursor: default;
}

.participantEmail {
  width: 45%;
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
  align-items: center;
}

.NoParticipantText {
  padding: 20px;
  font-weight: bold;
  font-size: 18px;
}

.openParticipantBoard {
  width: 10%;
}

.nonClickablePointer {
  cursor: default;
}

.hiddenParticipantname {
  display: none;
}

.interviewAppbarHeight {
  height: 60px;
}

/* .navbarLogoWidth {
  width: 150px;
} */

@media only screen and (max-height: 864px) and (max-width: 1536px) {
  .interviewAppbarHeight {
    height: 56px;
  }
  .navbarLogoWidth {
    width: 160px;
  }
}

@media only screen and (max-height: 720px) and (max-width: 1280px) {
  .interviewAppbarHeight {
    height: 53px;
  }
  
  .navbarLogoWidth {
    width: 150px;
  }
} 