.externalVideoComponent {
  width: 100%;
  margin-top: 20px;
  /* height: 100%; */
}

*, *::before, *::after {
  box-sizing: inherit;
  overflow: hidden;
}

@media screen and (max-width: 1200px) {
  .externalVideoComponent{
    margin-top: 25px;
  }
}

.externalVideoComponentTopNavBarContainer {
  height: 4vh;
}

.myMeetingContainer{
  width: 100vw;
  height: 95vh;
}

.externalVideoComponentTopNavBarContainer .MuiToolbar-regular{
  min-height: 50px !important;
}

@media only screen and (max-height: 864px) and (max-width: 1536px) {
  .myMeetingContainer{
    height: 94vh;
  }
}

@media only screen and (max-height: 720px) and (max-width: 1280px) {
  .myMeetingContainer{
    height: 93vh;
  }
} 