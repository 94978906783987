.meetingNotStartedView {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  font-size: x-large;
}

.meetingNotStartedViewContainer {
  display: flex;
  flex-direction: column;
  height: 40%;
  justify-content: space-around;
  color: var(--primaryColor);
}

.startMeetingButtonContainer {
  display: flex;
  justify-content: center;
}

.startMeetingButton {
  background-color: var(--primaryColor) !important;
  color: white !important;
}

.startMeetingButton:hover {
  background-color: white !important;
  color: var(--primaryColor) !important;
  border: solid 1px black !important;
  transform: scale(1.2) !important;
}
