.joinMeetingTopNavBarContainer {
  height: 10vh;
}

.joinMeetingTitleContainer {
  height: 10vh;
  color: var(--primaryColor);
  font-family: sans-serif;
  font-size: x-large;
  display: flex;
  justify-content: center;
}

.joinMeetingContentContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
}

.joinMeetingNameField {
  height: 13vh;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 3vh;
}

.joinMeetingEmailField {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 13vh;
  margin-top: 3vh;
}

.joinMeetingButton {
  display: flex;
  justify-content: center;
  height: 7vh;
  margin-top: 3vh;
}

.joinMeetingButtonDisabled {
  border: solid 1px black;
  border-radius: 5px;
  cursor: not-allowed;
}

.adminJoinMeetingButton {
  color: white !important;
  background-color: var(--primaryColor) !important;
  font-size: x-large;
  border-radius: 5px;
}

.adminJoinMeetingButton:hover {
  transform: scale(1.2) !important;
  color: var(--primaryColor) !important;
  background-color: white !important;
  border: solid 1px var(--primaryColor);
}

.adminJoinMeetingButtonDisabled {
  color: white;
  background-color: lightgray;
}
