.adminViewMeetingDetailsTopNavBarContainer {
  height: 10vh;
}

.adminViewMeetingDetailsMeetingDataContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.adminViewMeetingDetailsTitle {
  height: 5vh;
  font-size: x-large;
  font-family: sans-serif;
  color: var(--primaryColor);
}

.adminViewMeetingDetailsTimeContainer {
  height: 5vh;
  display: flex;
  font-size: larger;
  font-family: sans-serif;
  color: var(--primaryColor);
}

.adminViewMeetingDetailsHasMeetingStarted {
  height: 5vh;
  font-size: larger;
  font-family: sans-serif;
  color: var(--primaryColor);
}

.adminViewMeetingDetailsHasMeetingEnded {
  height: 5vh;
  font-size: larger;
  font-family: sans-serif;
  color: var(--primaryColor);
}

.adminViewMeetingDetailsPublicLink {
  display: flex;
  width: 100%;
  color: var(--primaryColor);
  overflow-y: scroll;
  text-overflow: ellipsis;
  justify-content: center;
}

.adminViewMeetingDetailsModeratorsContainer {
  margin-top: 3vh;
  display: flex;
  flex-direction: column;
  width: 80%;

}

.adminViewMeetingDetailsModerators {
  display: flex;
  width: 100%;
  min-height: 15vh;
  /* overflow: scroll; */
  flex-direction: column;
  border-radius: 5px;
  border: solid 1px var(--primaryColor);
}

.adminViewMeetingDetailsModeratorDetailsContainer {
  display: flex;
  justify-content: center;
}


.adminViewMeetingDetailsAllModerators {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 80%;
}

.adminViewMeetingDetailsModeratorEmail {
  display: flex;
  justify-content: center;
  color: var(--primaryColor);
  font-family: sans-serif;
  font-size: large;
}

.adminViewMeetingDetailsModeratorLink {
  color: var(--primaryColor);
  overflow: scroll;
}

.adminViewMeetingDetailsParticipantsContainer {
  margin-top: 3vh;
  margin-bottom: 5vh;
  display: flex;
  flex-direction: column;
  width: 80%;
}

.adminViewMeetingDetailsParticipantDetailsContainer {
  display: flex;
  justify-content: center;
}

.adminViewMeetingDetailsAllParticipants {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.adminViewMeetingDetailsParticipants {
  display: flex;
  width: 80%;
  min-height: 15vh;
  /* overflow: scroll; */
  flex-direction: column;
  border-radius: 5px;
  border: solid 1px var(--primaryColor);
}

.adminViewMeetingDetailsParticipantEmail {
  display: flex;
  justify-content: center;
  color: var(--primaryColor);
  font-family: sans-serif;
  font-size: large;
}

.adminViewMeetingDetailsParticipantLink {
  color: var(--primaryColor);
  overflow: scroll;
}

.adminViewMeetingPassword {
  display: flex;
  font-family: sans-serif;
  font-size: large;
  color: var(--primaryColor);
}

.contentDetailsContainer {
  display: flex;
  width: 80%;
  height: 300px;
  align-items: center;
  margin-top: 3vh;
  margin-bottom: 5vh;
  flex-direction: column;
  /* justify-content: space-around; */
  overflow-y: auto;

}

.contentDetailsTitleContainer {
  display: flex;
  font-size: medium;
  width: 100%;
}

.contentDetailsParticipantContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  border: 1px solid var(--primaryColor);
  padding: 3px;
  min-height: 150px !important;
  justify-content: space-between;
  /* margin-bottom: 3px; */
}

.contentDetailsSubmissionRecordContainer {
  margin-top: 1vh;
  margin-bottom: 1vh;
  border: 1px solid var(--primaryColor);
  padding: 5px;
  font-family: sans-serif;
  font-size: large;
  color: var(--primaryColor);
}

.contentDetailsParticipantSubmissions {
  width: 90%;
  overflow-y: auto;
  height: 90%;
}

.contentDetailsMetaDataContainer {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed black;
}

.contentDetailsLanguageIndicator {
  display: flex;
  width: 50%;
  justify-content: flex-start;
}

.contentDetailsSumittedAt {
  display: flex;
  width: 50%;
  justify-content: flex-end;
}

/* .contentDetailsParticipantName {
  height: 10%;
} */
