.company {    
  width: 100%;
  display: flex;
  justify-content: center;
  float: left;
}
.company-logo {
  height: 100%;
  float:left;
}

.codeShareOption {
  display: flex;
  width: 40px;
  flex-direction: column;
  height: 100%;
  align-items: center;
  padding-left: 5px;
}

.notesOption{
  display: flex;
  width: 40px;
  flex-direction: column;
  height: 100%;
  align-items: center;
  padding-left: 5px;
}

.whiteboardOption{
  display: flex;
  width: 40px;
  flex-direction: column;
  height: 100%;
  align-items: center;
  padding-left: 5px;
}